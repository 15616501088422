const wsuri = "wss://lof.lofchat.com/api/ws?plat=AARUSER&token=" + localStorage.getItem("token");
const ws_chat = {
    connction: {},
    connected: false,
    heart: {},
    last_msg: 0,

}

const subfun = new Map();
var onopen = function () {
    ws_chat.last_msg = new Date().getTime();
    ws_chat.connected = true
    ws_chat.heart = setInterval(() => {

        if (new Date().getTime() - ws_chat.last_msg > 10000) {

            ws_chat.connction.close(1000);
            onclose();

        }

    }, 3000);
    // console.log("chat_connected!")
}

var onclose = function (e) {
    console.log("链接断开:", e)
    reconnect();

}
function reconnect() {
    ws_chat.connected = false;
    //ws.close();
    ws_chat.connction = new WebSocket(wsuri);
    var timer = setTimeout(() => {
        if (ws_chat.connected == true) {
            clearInterval(timer)
        }
        ws_chat.conn();
    }, 5000);
}

var onmessage = function (data) {
    ws_chat.last_msg = new Date().getTime();


    let da = JSON.parse(data.data);


    if (da.ping != null) {

        ws_chat.connction.send('{"pong":' + da.ping + '}')


    } else {


        subfun.forEach((val) => {
            val(da)
        });



    }

}
var onerror = function (event) {
    console.log("链接错误", event);
}

ws_chat.Sub = function (callback, name) {
    subfun.set(name, callback)
    // subfun[name] = callback
}

ws_chat.UnSub = function (name) {

    delete subfun[name];
}
ws_chat.sendData = function (data) {

    let str = JSON.stringify(data)
    if (this.connected == false) {
        ws_chat.send(str)
    } else {
        let interval = setTimeout(() => {
            if (this.connected == true) {

                ws_chat.connction.send(str)
                clearInterval(interval);
            }

        }, 10);
    }


}
ws_chat.conn = function () {


    if (this.connected == false) {
        if (ws_chat.heart != null) {
            clearInterval(ws_chat.heart)
        }
        ws_chat.connction = new WebSocket(wsuri);
        ws_chat.connction.onopen = onopen
        ws_chat.connction.onclose = onclose
        ws_chat.connction.onmessage = onmessage
        ws_chat.onerror = onerror
        ws_chat.connected = true;

    }


}
export default ws_chat;
