<template>
	<div class="login-sign">
		<div class="logo">
			<img src="../assets/imgs/logo.png" />
			<h1>AAR Hedging</h1>
			<h2>{{ $t("siginaccount") }}</h2>
		</div>
		<div class="box">
			<div class="login">
				<input @keyup="check" @blur="check" v-model="login_form.mob" type="number" :placeholder="$t('mob')" />
				<input @keyup="check" @blur="check" v-model="login_form.pass_word" type="password"
					:placeholder="$t('pass')" />
				<div class="forget">
					<router-link to="/forgotpass"><a>{{ $t('forgot_pass') }}?</a></router-link>
				</div>
				<base-btn :dis="dis" :loading="loading" @click="login">{{ $t('login') }}</base-btn>


				<div class="create_account">
					{{ $t('nothaveacccount') }}?
					<router-link to="/signin"><a>{{ $t("reg") }}</a></router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
//import { Dialog } from "vant";
import input from "../components/input.vue";
import Fingerprint2 from "fingerprintjs2";
export default {
	components: { input },
	name: "Login",
	data() {
		return {
			old: false,
			showKeyboard: true,
			value: "",
			loading: false,
			otpstatus: false,
			status: 1,
			login_form: {
				mob: "",
				pass_word: "",
				finger_login: "",
				token: "",
				tp: 1,
			},
			dis: true,

			otptext: "Send"
		};
	},
	created() {
		let isold = this.$route.query.n;
		if (isold == null || isold != 1) {
			localStorage.setItem("oldapp", 1)
		}


		setTimeout(this.getInger(), 500);
		let token = localStorage.getItem("token");
		if (token != null) {
			this.$router.push("/home");
		}
		if (localStorage.getItem("mob") != null) {
			this.old = true;
			this.login_form.mob = localStorage.getItem("mob");
		}



	},


	methods: {

		getInger() {
			let option = {
				fonts: {
					extendedJsFonts: true,
				},
				excludes: {
					language: true,
					colorDepth: true,
					deviceMemory: true,
					pixelRatio: true,
					availableScreenResolution: true,
					timezoneOffset: true,
					timezone: true,
					sessionStorage: true,
					localStorage: true,
					indexedDb: true,
					addBehavior: true,
					openDatabase: true,
					cpuClass: true,
					doNotTrack: true,
					plugins: true,
					canvas: true,
					webglVendorAndRenderer: true,
					adBlock: true,
					hasLiedLanguages: true,
					hasLiedResolution: true,
					hasLiedOs: true,
					hasLiedBrowser: true,
					touchSupport: true,
					audio: true,
					enumerateDevices: true,
					hardwareConcurrency: true
				}
			};

			let _this = this;
			Fingerprint2.get(option, function (components) {
				const values = components.map(function (component, index) {
					if (index === 0) {
						//把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
						return component.value.replace(/\bNetType\/\w+\b/, "");
					}
					return component.value;
				});
				// 生成最终id murmur
				const murmur = Fingerprint2.x64hash128(values.join(""), 31);
				console.log(murmur);
				_this.login_form.finger_login = murmur;

			});
		},
		check() {
			if (this.login_form.mob.length == 10 && this.login_form.pass_word.length >= 6) {
				this.dis = false;
			} else {
				this.dis = true
			}

		},


		chengeType(val) {
			this.status = val;
		},


		login() {
			this.loading = true;
			console.log(this.form);
			this.post("user/login", this.login_form).then(res => {
				if (res.status === 0) {
					this.$toast(res.info);
					this.loading = false;

					return;
				} else {
					//this.$message.success("登录成功");
					localStorage.setItem("login_id", res.data.id);
					localStorage.setItem("name", res.data.name);
					localStorage.setItem("token", res.data.token);
					localStorage.setItem("code", res.data.code);
					this.$router.push("/home");
				}
				this.loading = false;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.login-sign {
	width: 100vw;
	height: 100vh;
	background: #fff;
	background-size: contain;

	.logo {
		background: #2744aa;

		display: block;
		text-align: center;
		padding-top: 4rem;
		padding-bottom: 4rem;

		img {
			width: 8rem;
			height: 8rem;
		}

		h1 {
			color: #fff;
			font-size: 4rem;
		}

		h2 {
			color: #97a3b6;
			font-weight: 400;
		}
	}

	.box {
		background: #fff;
		margin: 1rem;

		border-radius: 1rem;
		padding-top: 1rem;

		.tab {
			padding: 0 3rem;

			li {
				display: inline-block;
				list-style: none;
				width: 50%;
				text-align: center;
				padding: 1rem 0;
				font-size: 2rem;
				transition: all 0.3s ease-in-out;
				-webkit-transition: all 0.3s ease-in-out;

				/* Safari */
				&.ac {
					color: #eb4648;
					border-bottom: 3px solid #eb4648;
					font-weight: bold;
					box-shadow: 0px 4px 8px #f2f2f2;
					transition: all 0.3s ease-in-out;
					-webkit-transition: all 0.3s ease-in-out;
					/* Safari */
					font-size: 3rem;
				}
			}
		}

		.login {
			.forget {
				padding: 1rem;
				overflow: hidden;

				a {
					float: right;
					color: #466bb1;
				}
			}

			.create_account {
				color: #677489;
				text-align: center;
				padding: 2rem;
				overflow: hidden;

				a {
					color: #466bb1;
				}
			}

			.ext {
				&.btn {
					overflow: hidden;

					input {
						width: 40vw;
						float: left;
					}

					li {
						float: left;
						list-style: none;
						width: 30vw;

						height: 5rem;
						line-height: 5rem;
						background: #5564f5;
						text-align: center;
						color: #fff;
						border-radius: 3rem;
						margin-top: 2rem;
					}
				}
			}

			transition: all 0.3s ease-in-out;
			-webkit-transition: all 0.3s ease-in-out;
			/* Safari */
			padding: 2rem;

			input {
				border: none;
				background: #f8f9fd;
				border-radius: 0.8rem;
				height: 6rem;
				line-height: 6rem;
				display: block;
				width: 80vw;
				margin-top: 2rem;
				padding-left: 3rem;

				&::placeholder {
					color: #bfbfbf;
				}

				font-size: 2rem;
			}

			.login-btn {
				background: #653ceb;
				display: block;
				padding: 1.8rem;
				text-align: center;
				border-radius: 1.4rem;
				color: #fff;
				margin-top: 3rem;

				font-size: 2.5rem;

				font-weight: bold;
			}
		}
	}
}
</style>