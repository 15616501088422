<template>
	<div class="login-sign">
		<div class="logo">
			<img src="../assets/imgs/logo.png" />
		</div>
		<div class="title">AAR Hedging</div>

		<div class="info">
			<div class="item">
				<li>Version:</li>
				<li>v1.0</li>
			</div>
			<div class="item">
				<li>Size:</li>
				<li>2.8MB</li>
			</div>
			<div class="item">
				<li>Invitation code:</li>
				<li class="code">{{ code }}</li>
				<li class="copy capybut" @click="copyInviteCode" :data-clipboard-text="code">Copy</li>
			</div>
			<div class="ad">World leader in hedging trading</div>
			<a :href="host + '/app/aar.apk'">
				<div class="btn">Download</div>
			</a>
		</div>
	</div>
</template>
<script>
import Clipboard from "clipboard";
import { Dialog } from "vant";
export default {
	name: "download",
	data() {
		return {
			code: "66666"
		};
	},
	created() {
		document.title = "Serving customers. a 100% profit signal every day";
		let codes = this.$route.query.code;
		if (codes != "") {
			this.code = codes;
		}
	},
	methods: {
		copyInviteCode() {
			// 复制邀请码
			var clipboard = new Clipboard(".capybut");
			clipboard.on("success", e => {
				Dialog.alert({
					message: "Copied successfully"
				}).then(() => {
					clipboard.destroy(); // 释放内存
				});
			});
			clipboard.on("error", e => {
				//Toast("该浏览器不支持自动复制");
				clipboard.destroy(); // 释放内存
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.login-sign {
	width: 100vw;
	height: 100vh;
	background: #2c4dbf;
	background-size: contain;

	.logo {
		display: block;
		text-align: center;
		padding-top: 3rem;

		img {
			width: 8rem;
			height: 8rem;

			border-radius: 8rem;
			//box-shadow: 10px 10px 10px #58ccff;
		}
	}

	.title {
		display: block;

		padding: 2rem 0;
		font-size: 3rem;
		color: #fff;
		text-align: center;
	}

	.about {
		a {
			display: block;
			color: #2c4dbf;
			padding-left: 1rem;
			padding-top: 2rem;
		}

		.titles {
			font-size: 2rem;
			display: block;
			padding: 1rem;
			border-bottom: 1px solid #f2f2f2;
		}

		margin-bottom: 4rem;
	}

	.info {
		padding: 1rem;
		margin: 1rem;
		background: #fff;
		border-radius: 1rem;

		.ad {
			padding: 1rem;
			font-weight: bold;
			font-size: 3rem;
			color: #2c4dbf;
			border: 1px dashed #a7a5a5;
			border-radius: 1rem;
			background: #fff;
		}

		.item {
			display: block;
			padding: 1rem 0;

			li {
				font-size: 2rem;
				list-style: none;
				display: inline-block;

				&:first-child {
					color: #6c6f77;
				}

				&:last-child {
					color: #333;
				}

				&.code {
					font-size: 3rem;
					color: #2c4dbf;
				}

				&.copy {
					margin-left: 2rem;
					color: #2c4dbf;
				}
			}
		}

		.btn {
			background: #2c4dbf;
			display: block;
			padding: 1.8rem;
			text-align: center;
			border-radius: 6rem;
			color: #fff;
			margin-top: 3rem;
			margin-bottom: 2rem;
			font-size: 2.5rem;
			box-shadow: 10px 10px 5px #f2f2f2;
			font-weight: bold;
		}

		.btns {
			background-image: linear-gradient(90deg, #469df3, #eb4648);
			display: block;
			padding: 1rem;
			text-align: center;
			border-radius: 6rem;
			color: #fff;
			margin-top: 3rem;
			margin-bottom: 4rem;
			font-size: 2.5rem;
			box-shadow: 10px 10px 5px #f2f2f2;
			font-weight: bold;
		}
	}
}
</style>