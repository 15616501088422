var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home has-tabbar"},[_c('Header',{attrs:{"title":"AAR"}}),_c('div',{staticClass:"asset"},[_c('h1',[_vm._v(_vm._s(_vm.$t('total_asstes')))]),_c('div',{staticClass:"balance"},[_vm._v(_vm._s(_vm.formatToPrice(_vm.info.balance)))]),_c('div',{staticClass:"icon-group"},[_c('router-link',{attrs:{"to":"/deposit"}},[_c('div',{staticClass:"depoist"},[_c('img',{attrs:{"src":require("../assets/bit/recharge.png")}}),_c('li',[_vm._v(_vm._s(_vm.$t('deposit')))])])]),_c('router-link',{attrs:{"to":"/payout"}},[_c('div',{staticClass:"withdraw"},[_c('img',{attrs:{"src":require("../assets/bit/box.png")}}),_c('li',[_vm._v(_vm._s(_vm.$t('payout')))])])]),_c('router-link',{attrs:{"to":"/direct"}},[_c('div',{staticClass:"invite"},[_c('img',{attrs:{"src":require("../assets/bit/team.png")}}),_c('li',[_vm._v(_vm._s(_vm.$t('team')))])])]),_c('router-link',{attrs:{"to":{
			path: '/channel'
		}}},[_c('div',{staticClass:" service"},[_c('img',{attrs:{"src":require("../assets/bit/channel.png")}}),_c('li',[_vm._v("Channel")])])]),_c('router-link',{attrs:{"to":{
			path: '/service'
		}}},[_c('div',{staticClass:" service"},[_c('img',{attrs:{"src":require("../assets/bit/service.png")}}),_c('li',[_vm._v("Service")])])])],1)]),_c('div',{staticClass:"notice"},[_c('router-link',{attrs:{"to":{
			path: '/announcement', query: {
				id: _vm.notice.id
			}
		}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.notice.title))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.notice.created_at))])])],1),_c('div',{staticClass:"top"},[_c('div',{staticClass:"content"},_vm._l((_vm.product_list),function(item,index,i){return (i > 2)?_c('div',{key:i,staticClass:"item"},[_c('router-link',{attrs:{"to":{ path: '/kline', query: { code: item.code, name: item.title, title: item.name } }}},[_c('img',{class:{
			shake: item.zdf > 0
		},attrs:{"src":_vm.host + '/static/icon/' + item.title + '.png'}}),_c('div',{staticClass:"info"},[_c('li',[_vm._v(_vm._s(item.name))]),_c('li',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"data"},[_c('i',[_vm._v(_vm._s(item.price))]),_c('i',{class:{ shake: item.zdf > 0, call: item.zdf >= 0, put: item.zdf < 0 }},[_vm._v(_vm._s(item.zdf)+"%")])])])],1):_vm._e()}),0)]),_vm._m(0),_c('Tabbar',{attrs:{"now":'1'}}),_c('van-popup',{staticClass:"notice2",attrs:{"closeable":""},model:{value:(_vm.showPop2),callback:function ($$v) {_vm.showPop2=$$v},expression:"showPop2"}},[_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.msgs)}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ad"},[_c('h1',[_vm._v("Earn at least 10%")]),_vm._v(" Invite friends to join get bonus ")])}]

export { render, staticRenderFns }