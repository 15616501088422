<template>
	<div class="login-sign">
		<div class="close"><router-link to="/login"><img src="../assets/bit/x.png" /></router-link></div>
		<div class="logo">
			<h1>Find My Password</h1>
		</div>
		<div class="step">
			<div :class="{ ac: step == 0 ? true : false }"></div>
			<div :class="{ ac: step == 1 ? true : false }"></div>


		</div>
		<div class="box">
			<div class="login">
				<div v-show="step == 0">
					<input v-model="login_form.mob" type="number" placeholder="Mobile phone number" />

				</div>

				<div v-show="step == 1">


					<input maxlength="6" v-model="login_form.code" type="text" placeholder="SMS OTP" />
				</div>
				<div v-show="step == 1">


					<input v-model="login_form.pass" type="password" placeholder="New Password" />
				</div>
				<div class="login-btn" @click="signin">{{ btn_text }}</div>


			</div>
		</div>
	</div>
</template>
<script>
import { Dialog } from "vant";
import input from "../components/input.vue";

export default {
	components: { input },
	name: "SignUp",
	data() {
		return {
			btn_text: "Continue",
			step: 0,
			login_form: {
				mob: "",
				pass: "",
				code: ""
			},


		};
	},
	created() {



	},
	activated() {

	},
	methods: {
		signin() {
			if (this.step == 0) {
				this.sendOtp();
			}

			if (this.step == 1) {
				this.repass()
			}


		},


		chengeType(val) {
			this.status = val;
		},
		repass() {
			this.post("reset_pass", this.login_form).then(res => {
				if (res.status == 1) {


					Dialog.alert({
						message: res.info
					}).then(() => { });
				} else {
					Dialog.alert({
						message: res.info
					}).then(() => { });
				}


			});
		},
		sendOtp() {


			this.post("otp", { mob: this.login_form.mob }).then(res => {
				if (res.status == 1) {
					this.btn_text = "Reset Password"
					this.step = 1;
					Dialog.alert({
						message: res.info
					}).then(() => { });
				} else {
					Dialog.alert({
						message: res.info
					}).then(() => { });
				}


			});
		},

	}
};
</script>

<style lang="scss" scoped>
.close {
	padding: 2rem;


}

.login-sign {
	width: 100vw;
	height: 100vh;
	background: #fff;
	background-size: contain;

	.step {
		div {
			margin-left: 1rem;
			display: inline-block;
			width: 42%;
			height: 1rem;
			background: #2744aa;
			opacity: 0.1;
			border-radius: 1rem;

			&.ac {
				transition: all 0.5s ease-in;
				-webkit-transition: all 0.5s ease-in;
				/* Safari */
				background: #2744aa;
				opacity: 1;
			}
		}
	}

	.logo {
		display: block;
		text-align: center;
		padding-top: 2rem;
		padding-bottom: 2rem;

		img {
			width: 8rem;
			height: 8rem;
		}

		h1 {
			padding-left: 3.5rem;
			text-align: left;
			color: #101729;
			font-size: 3rem;

		}

		h2 {
			color: #97a3b6;
			font-weight: 400;
		}
	}

	.box {
		background: #fff;
		margin: 1rem;

		border-radius: 1rem;
		padding-top: 1rem;

		.tab {
			padding: 0 3rem;

			li {
				display: inline-block;
				list-style: none;
				width: 50%;
				text-align: center;
				padding: 1rem 0;
				font-size: 2rem;
				transition: all 0.3s ease-in-out;
				-webkit-transition: all 0.3s ease-in-out;

				/* Safari */
				&.ac {
					color: #eb4648;
					border-bottom: 3px solid #eb4648;
					font-weight: bold;
					box-shadow: 0px 4px 8px #f2f2f2;
					transition: all 0.3s ease-in-out;
					-webkit-transition: all 0.3s ease-in-out;
					/* Safari */
					font-size: 3rem;
				}
			}
		}

		.login {
			.forget {
				padding: 1rem;
				overflow: hidden;

				a {
					float: right;
					color: #2744aa;
				}
			}

			.create_account {
				color: #677489;
				text-align: center;
				padding: 2rem;
				overflow: hidden;

				a {
					color: #2744aa;
				}
			}

			.ext {
				&.btn {
					overflow: hidden;

					input {
						width: 40vw;
						float: left;
					}

					li {
						float: left;
						list-style: none;
						width: 30vw;

						height: 5rem;
						line-height: 5rem;
						background: #5564f5;
						text-align: center;
						color: #fff;
						border-radius: 3rem;
						margin-top: 2rem;
					}
				}
			}

			transition: all 0.3s ease-in-out;
			-webkit-transition: all 0.3s ease-in-out;
			/* Safari */
			padding: 2rem;

			input {
				border: none;
				background: #f8f9fd;
				border-radius: 0.8rem;
				height: 6rem;
				line-height: 6rem;
				display: block;
				width: 80vw;
				margin-top: 2rem;
				padding-left: 3rem;

				&::placeholder {
					color: #bfbfbf;
				}

				font-size: 2rem;
			}

			.login-btn {
				&.ac {
					background: #f8f5fe;
					color: #643beb;
				}

				background: #2744aa;
				display: block;
				padding: 1.8rem;
				text-align: center;
				border-radius: 1.4rem;
				color: #fff;
				margin-top: 3rem;

				font-size: 2.5rem;

				font-weight: bold;
			}
		}
	}
}
</style>